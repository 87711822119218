<template>
  <div class="project">
    <van-nav-bar :title="$t('非遗项目')" left-arrow @click-left="onClickLeft" />
    <div class="cut">
      <p @click="btn(1)">
        {{ $t("非遗项目") }}<span :class="{ active: tab == 1 }"></span>
      </p>
      <p @click="btn(2)">传承人<span :class="{ active: tab == 2 }"></span></p>
    </div>
    <div>
      <van-dropdown-menu active-color="#1296db">
        <van-dropdown-item
          v-model="value1"
          :options="option1"
          @change="handlFilter1"
        />
        <van-dropdown-item
          v-model="value2"
          :options="option2"
          @change="handlFilter2"
        />
      </van-dropdown-menu>
      <ul>
        <li v-for="(item, index) in listData[list_index]" :key="index">
          <img :src="item.imgUrl" alt="" @click="onImgs1(index)" />
          <h5>{{ item.intangibleCulturalHeritageName }}</h5>
          <p>{{ item.briefIntroduction }}</p>
          <!-- <div class="grade">{{ item.level }}</div> -->
          <div class="grade">
            <span v-if="item.level == '1'"> 世界级 </span>
            <span v-if="item.level == '2'"> 国家级 </span>
            <span v-if="item.level == '3'"> 省级 </span>
            <span v-if="item.level == '3'"> 市级 </span>
            <span v-if="item.level == '5'"> 县级 </span>
          </div>
          <div class="sort">{{ item.lists }}</div>
          <div class="amount">
            <van-rate
              v-model="item.value"
              icon="star"
              void-icon="star-o"
              :count="1"
              color="#ffd21e"
              @change="onChange1(index)"
            />
            <span>阅 {{ item.readNumber }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapMutations } from "vuex";
export default {
  name: "project",
  data() {
    return {
      tab: 1, //选项卡的值
      value1: 0, //级别：默认第一项
      value2: 1, //分类：默认第一项
      option1: [
        //下拉菜单选项
        { text: "全部级别", value: 0 },
        { text: "世界级", value: 1 },
        { text: "国家级", value: 2 },
        { text: "省级", value: 3 },
        { text: "市级", value: 4 },
        { text: "县级", value: 5 },
      ],
      option2: [
        //下拉菜单选项
        { text: this.$t("各派戏曲"), value: 1 },
        { text: this.$t("传统音乐"), value: 2 },
        { text: this.$t("传统美术"), value: 3 },
        { text: this.$t("民族舞蹈"), value: 4 },
        { text: this.$t("民间文学"), value: 5 },
        { text: this.$t("传统医学"), value: 6 },
        { text: this.$t("传统技艺"), value: 7 },
        { text: this.$t("民俗文化"), value: 8 },
        { text: this.$t("曲艺"), value: 9 },
        { text: this.$t("民族服饰"), value: 10 },
      ],
      list1: [],

      value3: 1, //级别：默认第一项
      value4: 1, //分类：默认第一项
      option3: [
        //下拉菜单选项
        { text: "国家级别", value: 1 },
      ],
      option4: [
        //下拉菜单选项
        { text: "全部分类", value: 1 },
        { text: "各派戏曲", value: 2 },
        { text: "传统音乐", value: 3 },
      ],
      list2: [],
      // ------------------------------------------------------------
      // 封装接口路径
      urlIndex: "",
      dataURl: [
        "/getTraditionalOpera", //戏曲
        "/getMusic", // 音乐
        "/getFineArts", //美术
        "/getDance", //舞蹈dance
        "/getLiterature", // 文学
        "/getMedicalScience", // 医学
        "/getSkill", // 技艺
        "/getCulture", //民俗文化
        "/getMusicalInstruments", //乐器
        "/getClothesAccessories", // 服饰
      ],
      index: "",
      listData: [],
      value: 0,
      list_index: 0,
      indexValue: {
        各派戏曲: 0,
        传统音乐: 1,
        传统美术: 2,
        民族舞蹈: 3,
        民间文学: 4,
        传统医学: 5,
        传统技艺: 6,
        民俗文化: 7,
        曲艺: 8,
        民族服饰: 9,
      },
      off: false,
    };
  },
  computed: {
    ...mapState(["lists"]),
  },
  created() {
    if (sessionStorage.getItem("wxUser") == null) {
      this.off = true;
    } else {
      this.off = false;
    }
    this.addList();
  },
  methods: {
    ...mapMutations(["setDetails"]),
    // 获取数据
    addList() {
      let params = new FormData();
      let userId = JSON.parse(sessionStorage.getItem("wxUser")) || null;
      if (userId != null) {
        params.append("userId", userId.id);
      }
      let abbreviation = JSON.parse(
        sessionStorage.getItem("abbreviation")
      ).travelAbbreviation;
      params.append("abbreviation", abbreviation);
      this.urlIndex = this.indexValue[this.lists];
      this.getList(this.indexValue[this.lists], params);
    },
    // 获取非遗列表
    getList(urlIndex, params) {
      this.$axios
        .post("/api/ich" + this.dataURl[urlIndex], params)
        .then((res) => {
          if (res.code == 0) {
            this.listData = [];
            let list1 = res.data.list1.map((item) => {
              item.value = 0;
              if (item.flag == true) {
                item.value = 2;
              }
              return item;
            });
            let list2 = res.data.list2.map((item) => {
              item.value = 0;
              if (item.flag == true) {
                item.value = 2;
              }
              return item;
            });
            this.listData.push(list1.concat(list2));
            this.listData.push(list1);
            this.$forceUpdate();
          } else {
            console.log("请求失败");
            this.$router.go(-1);
          }
        });
    },
    //页面回退
    onClickLeft() {
      this.$router.go("-1");
    },
    //选项卡切换
    btn(key) {
      this.tab = key;
      this.list_index = key - 1;
    },
    //点击跳转
    //筛选
    handlFilter1(value) {
      let params = new FormData();
      let userId = JSON.parse(sessionStorage.getItem("wxUser")) || null;
      if (userId != null) {
        params.append("userId", userId.id);
      }
      if (value) params.append("level", value);
      this.getList(this.urlIndex, params);
    },
    handlFilter2(value) {
      let params = new FormData();
      let userId = JSON.parse(sessionStorage.getItem("wxUser")) || null;
      if (userId != null) {
        params.append("userId", userId.id);
      }
      this.getList(value - 1, params);
    },
    //收藏事件
    onChange1(i) {
      event.stopPropagation();
      if (this.off) {
        this.listData[this.list_index][i].value = 0;
        this.$toast.warn("请先登录微信");
        return;
      }
      this.listData[this.list_index][i].value = !this.listData[this.list_index][
        i
      ].flag
        ? this.listData[this.list_index][i].value + 1
        : this.listData[this.list_index][i].value - 1;
      this.listData[this.list_index][i].flag =
        !this.listData[this.list_index][i].flag;
      if (this.listData[this.list_index][i].value == 2) {
        // 调用收藏接口
        this.getChange1list(i);
      } else {
        this.deleteMsg(this.listData[this.list_index][i].id);
      }
    },
    // 添加收藏
    getChange1list(index) {
      let params = new FormData();
      let userId = JSON.parse(sessionStorage.getItem("wxUser")) || null;
      if (userId == null) {
        return false;
      }
      let type = 4;
      let intangibleCulturalHeritageId =
        this.listData[this.list_index][index].id;
      params.append("userId", userId.id);
      // params.append("type", type);
      params.append(
        "intangibleCulturalHeritageId",
        intangibleCulturalHeritageId
      );
      // 发送请求  获取数据
      this.$axios
        .post("/api/msg/getCollectionIntangibleCulturalHeritage", params)
        .then((res) => {
          console.log(res, 12121);
        });
    },
    // 删除收藏
    deleteMsg(index) {
      let params = new FormData();
      let userId = JSON.parse(sessionStorage.getItem("wxUser")) || null;
      if (userId == null) {
        return false;
      }
      params.append("userId", userId.id);
      params.append("id", index);
      this.$axios
        .post("/api/msg/getCancelIntangibleCulturalHeritage", params)
        .then((res) => {
          console.log(res, 111111);
        });
    },
    onImgs1(i) {
      this.setDetails(this.listData[this.list_index][i]);
      this.$router.push("/feiyiItem");
    },
  },
};
</script>

<style lang="less" scoped>
.project {
  padding-top: 2.5rem;
  margin-bottom: 7.5vh;
}
/deep/ .van-nav-bar {
  width: 100%;
  height: 1.1rem;
  background: #1296db;
  color: #fff;
  position: fixed;
  top: 0;
  i {
    font-size: 0.4rem;
    color: #fff;
  }
  div {
    font-weight: 500;
    font-size: 0.34rem;
    padding-top: 0.1rem;
    color: #fff;
  }
}
/deep/ .van-hairline--bottom::after {
  border: none;
}
/** 选项卡 */
.cut {
  width: 100%;
  display: flex;
  justify-content: space-around;
  background: #1296db;
  font-size: 0.32rem;
  color: #fff;
  font-weight: 500;
  padding-bottom: 0.06rem;
  position: fixed;
  top: 1.1rem;
  z-index: 9;
  p {
    span {
      display: block;
      width: 0.6rem;
      height: 0.06rem;
      border-radius: 0.06rem;
      margin: 0 auto;
      margin-top: 0.03rem;
    }
    .active {
      background: #fff;
    }
  }
}
/** 分类 */
/deep/ .van-dropdown-menu__bar {
  width: 100%;
  height: 0.8rem;
  position: fixed;
  top: 1.74rem;
  z-index: 2;
  span {
    font-size: 0.24rem;
    transform: scale(1.3);
  }
}
/deep/ .van-cell {
  font-size: 0.3rem;
  line-height: 0.5rem;
}
/** 戏曲内容 */
ul {
  width: 100%;
  padding: 0.3rem;
  li {
    position: relative;
    margin-bottom: 0.4rem;
    img {
      width: 100%;
      height: 3.34rem;
      border-radius: 0.12rem;
    }
    h5 {
      font-size: 0.28rem;
      font-weight: 400;
      color: #666666;
      padding: 0 0.2rem;
      margin-top: 0.1rem;
    }
    p {
      font-size: 0.27rem;
      color: #999999;
      padding: 0 0.2rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .grade {
      width: 1.2rem;
      line-height: 0.42rem;
      text-align: center;
      color: #fff;
      font-size: 0.2rem;
      background: #ab3435;
      border-radius: 0.12rem 0 0.12rem 0;
      position: absolute;
      top: 0;
      left: 0;
    }
    .sort {
      width: 1.2rem;
      line-height: 0.42rem;
      text-align: center;
      color: #fff;
      font-size: 0.2rem;
      background: #716160;
      border-radius: 0 0.12rem 0 0.12rem;
      position: absolute;
      top: 0;
      right: 0;
    }
    .amount {
      width: 100%;
      line-height: 0.6rem;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      border-radius: 0 0 0.12rem 0.12rem;
      padding-right: 0.3rem;
      text-align: right;
      font-size: 0.2rem;
      color: #fff;
      position: absolute;
      bottom: 19%;

      /deep/ .van-rate__icon {
        font-size: 0.3rem;
      }
      span {
        margin-left: 0.2rem;
      }
    }
  }
  li:last-child {
    margin: 0;
  }
}
</style>